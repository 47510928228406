export default async function useSectionModules(section: string) {
  const nuxtApp = useNuxtApp()
  const url = apiPaths.sectionModule(section)

  const cacheTime = useCacheTime(url)

  const { data, error, pending, refresh } = await useFetch<SectionModule[]>(url, {
    key: url,
    // getCachedData(key) {
    //   const data = nuxtApp.payload.data[key] || nuxtApp.static.data[key]

    //   const expirationDate = new Date(cacheTime.value)

    //   expirationDate.setTime(expirationDate.getTime() + 300000) // 5 minutes

    //   const isExpired = expirationDate.getTime() < Date.now()

    //   if (isExpired) {
    //     cacheTime.value = new Date()
    //     // Refetch the data
    //     return
    //   }

    //   return data
    // },
  })

  return { data, pending, error, refresh }
}

export async function useSectionModComplete(slug: string, type: string) {
  const key = apiPaths.sectionModule(type)
  const { refresh } = await useDataOrFetch(key)

  const url = apiPaths.sectionModuleComplete(slug)

  const resp = await $fetch<UserModule>(url, {
    method: 'PATCH',
    body: {},
  })

  await refresh()

  return { resp }
}

export async function useSectionModStart(slug: string, type: string) {
  const key = apiPaths.sectionModule(type)
  const { refresh } = await useDataOrFetch(key)

  const url = apiPaths.sectionModuleStart(slug)

  const resp = await $fetch<UserModule>(url, {
    method: 'PATCH',
    body: {},
  })

  await refresh()

  return { resp }
}
